<script setup lang="ts">
import { computed } from "vue";
import type { NotificationItem } from "@kyvg/vue3-notification";
// import { useProgress } from "../composables/useProgress";

// const { $t } = useNuxtApp();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  item: NotificationItem;
  close: () => void;
}>();

const imageUrl = computed(
  () =>
    new URL(`../../../assets/images/${props.item.type}-bg.svg`, import.meta.url).href,
);

const closeIcon =new URL(`../../../assets/images/close-notification.svg`, import.meta.url).href; 
// const { progress } = useProgress(props.item.length);
const title = computed(() => {
  return props.item.title ;
  // return props.item.title || $t(`dscl.notification.${props.item.type}`);
});
</script>

<template>
  <div class="progress-bar-container">
    <span :class="['progression', props.item.type]" />
  </div>
  <div
    class="apptegy__notification-body"
    :class="`apptegy__notification-${props.item.type}`"
  >
    <div class="apptegy__notification-message">
      <img :src="imageUrl" class="apptegy__notification-icon" />
      <div>
        <div class="apptegy__notification-title">
          {{ title }}
        </div>
        <p class="apptegy__notification-text">
          {{ props.item.text }}
        </p>
      </div>
    </div>
  </div>
  <button
    :aria-label="$t('dscl.notification.closeAlt')"
    class="apptegy__notification-close-btn"
    @click="props.close"
  >
    <img :src="closeIcon" role="presentation" />
  </button>
</template>

<style scoped>
.progress-bar-container {
  position: relative;
  background-color: var(--dscl-disabled-accent, var(--grey-30));
  border-radius: 4px 0px 0px 4px;
  height: 3px;

  .progression {
    --progression-mode-color: var(--dscl-primary, var(--purple));
    display: block;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    position: relative;
    overflow: hidden;
    background-color: var(--progression-mode-color);
    width: v-bind(progress);

    &.success {
      --progression-mode-color: var(--dscl-success-primary-color, var(--teal));
    }
    &.info {
      --progression-mode-color: var(--dscl-info-primary-color, var(--grey-50));
    }
    &.warning {
      --progression-mode-color: var(
        --dscl-warning-primary-color,
        var(--orange)
      );
    }
    &.error {
      --progression-mode-color: var(--dscl-error-primary-color, var(--red));
    }
  }
}

.apptegy__notification-body {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  background: #fff;

  .apptegy__notification-message {
    display: flex;
    flex-grow: 1;
  }

  .apptegy__notification-icon {
    margin-right: 8px;
  }

  .apptegy__notification-title {
    font: var(--font-medium);
    color: #333 !important;
    margin-bottom: 8px;
  }

  .apptegy__notification-text {
    line-height: 1.15;
    font-size: 16px;
    margin: 0;
  }

  .apptegy__notification-progress {
    font-weight: 500;
    margin: 8px 0 0;
  }

  .apptegy__notification-action {
    margin: 10px 15px 0 0;
  }
}

.apptegy__notification-close-btn {
  height: 32px;
  width: 28px;
  border: none;
  appearance: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
}
</style>
