<template>
  <a-notifications group="flash" position="bottom left">
  </a-notifications>
</template>

<script>
export default {
  name: "Notify",
  props: {
    group: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$notify({
      group: this.group,
      type: this.type,
      duration: this.duration,
      title: "Important message",
      text: this.text,
    });
  },
};
</script>

<style lang="scss" scoped></style>
